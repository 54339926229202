import React, { useState, useEffect, useRef } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';
import FooterThree from '../Footer/FooterOne';
import Header from '../Header/HeaderOne';
import { pageTitle } from '../PageTitle';
// Import SortableJS with MultiDrag plugin
import Sortable, { MultiDrag } from 'sortablejs';

// Mount the MultiDrag plugin
Sortable.mount(new MultiDrag());

const Christmas = () => {
    pageTitle('Christmas List');
    const [skillBar, setSkillBar] = useState(false);

    // Ref to the sortable container
    const sortableRef = useRef(null);

    useEffect(() => {
        if (sortableRef.current) {
            // Initialize Sortable
            const sortable = new Sortable(sortableRef.current, {
                animation: 150, // Smooth animations
                multiDrag: true, // Enable MultiDrag plugin
                selectedClass: 'selected', // Class for selected items
                onEnd: (evt) => {
                    console.log('New order:', sortable.toArray());
                },
            });

            // Cleanup on component unmount
            return () => sortable.destroy();
        }
    }, []);

    return (
        <>
            <Header></Header>
            <ScrollTrigger onEnter={() => setSkillBar(true)} onExit={() => setSkillBar(false)}>
                <div id="main" className="about__page section-padding pb-100">
                    <div className="container">
                        <div className="row mb-60">
                            <div className="col-xl-6 col-lg-8">
                                <div className="about__page-title">
                                    <span className="subtitle">Presents</span>
                                    <h2>My Christmas List</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-100">
                            <div className="col-xl-12">
                                <div className="about__page-area">
                                    <div className="about__page-area-experience">
                                        <h3>
                                            <span className="counter">
                                                {skillBar && <CountUp start={0} end={365} duration={3} delay={0}></CountUp>}+
                                            </span>
                                        </h3>
                                        <p>Days I've been good this year</p>
                                    </div>
                                    <img
                                        src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/7f652aec-92db-4ac0-4cd3-9af3e79f4e00/public"
                                        alt="Family"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div ref={sortableRef} className="sortable-list">
                                {/* Sortable items */}
                                <div className="sortable-item" data-id="3">
                                    <a href="https://amzn.to/3VJiZSn" target="_blank" rel="noreferrer">October Mountain Products Versa-Cradle Bow Wide Limb Bow Vise Only</a>
                                    <span>$289.13</span>
                                </div>
                                <div className="sortable-item" data-id="4">
                                    <a href="https://amzn.to/3ZCIDul" target="_blank" rel="noreferrer">CLIQ Portable Camping Chair: ANY COLOR</a>
                                    <span>$79.99</span>
                                </div>
                                <div className="sortable-item" data-id="4">
                                    <a href="https://amzn.to/3Vkk16W" target="_blank" rel="noreferrer">Under Armour Men's Tactical Tech T-Shirt: size Medium</a>
                                    <span>$17.49</span>
                                </div>
                                <div className="sortable-item" data-id="4">
                                    <a href="https://www.underarmour.com/en-us/p/shirts_and_tops/mens_ua_meridian_short_sleeve/1386973.html?dwvar_1386973_color=390" target="_blank" rel="noreferrer">Men's UA Meridian Short Sleeve: size Medium</a>
                                    <span>$60.00</span>
                                </div>
                                <div className="sortable-item" data-id="4">
                                    <a href="https://www.lowes.com/pd/Kobalt-46-1-in-L-x-37-2-in-H-9-Drawers-Rolling-Black-Wood-Work-Bench/5013467719" target="_blank" rel="noreferrer">Kobalt 46.1-in L x 37.2-in H 9-Drawers Rolling Black Wood Work Bench
                                    </a>
                                    <span>$398.00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollTrigger>

            <FooterThree></FooterThree>
        </>
    );
};

export default Christmas;
